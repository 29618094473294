import React, { Component } from 'react';
import data from './data.json';
import * as math from 'mathjs'


// Function to trigger CSV download
function downloadCSV(data) {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");
    link.style.visibility = "hidden";
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);  // optional: free up memory
  }


class ModelInputs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parameters: {
                location: props.location || '',
                baseTemperature: props.baseTemperature || '',
                temperatureUnit: props.temperatureUnit || '',
                calculationType: props.calculationType || '',
                startDate: props.startDate || '',
                endDate: props.endDate || '',
                gddThreshold: props.gddThreshold || '',
                thresholdDay: props.thresholdDay || '',
            },
            result: [],
            csvData: '',
        };
        // this.handleThresholdDayChange = this.handleThresholdDayChange.bind(this);
    }
    
    handleLocationChange(event) {
        var parameters = this.state.parameters;
        parameters.location = event.target.value;
        this.setState({parameters: parameters})
    }

    handleLatitudeChange(event) {
        const parameters = { ...this.state.parameters, latitude: event.target.value };
        this.setState({ parameters });
    }
    
    handleLongitudeChange(event) {
        const parameters = { ...this.state.parameters, longitude: event.target.value };
        this.setState({ parameters });
        }

    handleBaseTemperatureChange(event) {
        var parameters = this.state.parameters;
        parameters.baseTemperature = event.target.value;
        this.setState({parameters: parameters})
    }

    handleTemperatureUnitChange(event) {
        var parameters = this.state.parameters;
        parameters.temperatureUnit = event.target.value;
        this.setState({parameters: parameters})
    }

    handleCalculationTypeChange(event) {
        var parameters = this.state.parameters;
        parameters.calculationType = event.target.value;
        this.setState({parameters: parameters})
    }

    handleStartDateChange(event) {
        var parameters = this.state.parameters;
        parameters.startDate = event.target.value;
        this.setState({parameters: parameters})
    }

    handleEndDateChange(event) {
        var parameters = this.state.parameters;
        parameters.endDate = event.target.value;
        this.setState({parameters: parameters})
    }

    handleGddThresholdChange(event) {
        var parameters = this.state.parameters;
        parameters.gddThreshold = event.target.value;
        this.setState({parameters: parameters})
        }
    
    handleThresholdDayChange(event) {
        var parameters = this.state.parameters;
        parameters.thresholdDay = event.target.value;
        this.setState({parameters: parameters})
        }


    handleButtonClicked() {
        var latitude = this.state.parameters.latitude;
        var longitude = this.state.parameters.longitude;
        const unit = this.state.parameters.temperatureUnit
        const calcType = this.state.parameters.calculationType
        const threshold = this.state.parameters.gddThreshold
        
        // api request
        var rootUrl = 'https://data.rcc-acis.org/GridData?'
        var elems = "maxt,mint"
        var params = 'params={"sdate":"' + this.state.parameters.startDate + '","edate":"' + this.state.parameters.endDate + '","loc":"' + longitude + ',' + latitude + '","grid":"21","elems":"' + elems + '"}'
        var url = rootUrl + params
        
        // get data
        if ( 
            this.state.parameters.temperatureUnit === 'F' ) {
                var baseTempF = this.state.parameters.baseTemperature
                var baseTempC = (this.state.parameters.baseTemperature - 32) * (5/9)
                // console.log('Base temp F: ',baseTempF)
                // console.log('Base temp C: ',baseTempC)
        } else if ( 
            this.state.parameters.temperatureUnit === 'C' ) {
                baseTempF = (this.state.parameters.baseTemperature * 9/5 ) + 32
                baseTempC = this.state.parameters.baseTemperature
                // console.log('Base temp F: ',baseTempF)
                // console.log('Base temp C: ',baseTempC)
        }

        const FetchData = async () => {
            let response = await fetch(url);

            // console.log('RESPONSE: ',response.status);

            if (response.status === 200 ) {
                var data = await response.json();                
            }

            
            function gddSimpleAveF(a) {
                if ( a - baseTempF < 0 ) {
                    return 0
                } else if ( a - baseTempF >= 0 ) {
                    return a - baseTempF
                }
            }

            function gddSimpleAveC(a) {
                if ( a - baseTempC < 0 ) {
                    return 0
                } else if ( a - baseTempC >= 0 ) {
                    return a - baseTempC
                }
            }

            function gddSineWaveF(x,b,c) {
                if ( x < baseTempF && b < baseTempF ) {
                    return 0
                } else if (x >= baseTempF && b >= baseTempF ) {
                    return c - baseTempF
                } else if (x >= baseTempF && b < baseTempF ) {
                    var w = (x - b) / 2
                    var a = math.asin( (baseTempF - c) / w )
                    var gdd = ((w * math.cos(a)) - ((baseTempF - c ) * ((3.14/2) - a))) / 3.14
                    return gdd
                }
            }


            function gddSineWaveC(x,b,c) {
                if ( x < baseTempC && b < baseTempC ) {
                    return 0
                } else if (x >= baseTempC && b >= baseTempC ) {
                    return c - baseTempC
                } else if (x >= baseTempC && b < baseTempC ) {
                    var w = (x - b) / 2
                    var a = math.asin( (baseTempC - c) / w )
                    var gdd = ((w * math.cos(a)) - ((baseTempC - c ) * ((3.14/2) - a))) / 3.14
                    return gdd
                    }
                }

            var AccumulatorGddFahrenheitSimple = 0
            function RunningSumGddFahrenheitSimple(a) {
                AccumulatorGddFahrenheitSimple = AccumulatorGddFahrenheitSimple + a
                return AccumulatorGddFahrenheitSimple
            }

            var AccumulatorGddFahrenheitSine = 0
            function RunningSumGddFahrenheitSine(a) {
                AccumulatorGddFahrenheitSine = AccumulatorGddFahrenheitSine + a
                return AccumulatorGddFahrenheitSine
            }

            var AccumulatorGddCelsiusSimple = 0
            function RunningSumGddCelsiusSimple(a) {
                AccumulatorGddCelsiusSimple = AccumulatorGddCelsiusSimple + a
                return AccumulatorGddCelsiusSimple
            }

            var AccumulatorGddCelsiusSine = 0
            function RunningSumGddCelsiusSine(a) {
                AccumulatorGddCelsiusSine = AccumulatorGddCelsiusSine + a
                return AccumulatorGddCelsiusSine
            }

            const DataPacket = () => {
                var result = data.data.map((o) => ({
                    ...o,
                    date: o[0],
                    tempMaxF: Number(o[1]),
                    tempMinF: Number(o[2]),
                    tempMeanF: Number(( o[1] + o[2] ) / 2),
                    gddFahrenheitSimple: Number(gddSimpleAveF((( o[1] + o[2] ) / 2))),
                    gddFahrenheitSine: Number(gddSineWaveF(o[1],o[2],(( o[1] + o[2] ) / 2))),
                    gddFahrenheitSimpleCumsum: Number(RunningSumGddFahrenheitSimple(gddSimpleAveF((( o[1] + o[2] ) / 2)))),
                    gddFahrenheitSineCumSum: Number(RunningSumGddFahrenheitSine(gddSineWaveF(o[1],o[2],(( o[1] + o[2] ) / 2)))),
                    tempMaxC: Number(( o[1] - 32 ) * ( 5/9 )),
                    tempMinC: Number(( o[2] - 32 ) * ( 5/9 )),
                    tempMeanC: Number(((((o[1]-32) * (5/9)) + ((o[2]-32) * (5/9))) / 2)),
                    gddCelsiusSimple: Number(gddSimpleAveC(((( o[1] - 32 ) * ( 5/9 )) + (( o[2] - 32 ) * ( 5/9 ))) / 2)),
                    gddCelsiusSine: Number(gddSineWaveC((( o[1] - 32 ) * ( 5/9 )),(( o[2] - 32 ) * ( 5/9 )),((((o[1]-32) * (5/9)) + ((o[2]-32) * (5/9))) / 2))), 
                    gddCelsiusSimpleCumSum: Number(RunningSumGddCelsiusSimple(gddSimpleAveC(((( o[1] - 32 ) * ( 5/9 )) + (( o[2] - 32 ) * ( 5/9 ))) / 2))),
                    gddCelsiusSineCumSum: Number(RunningSumGddCelsiusSine(gddSineWaveC((( o[1] - 32 ) * ( 5/9 )),(( o[2] - 32 ) * ( 5/9 )),((((o[1]-32) * (5/9)) + ((o[2]-32) * (5/9))) / 2))))
                }));
                const csvData = convertToCSV(result);
                this.setState({csvData});
                console.log("csvData state: ", this.state.csvData)
                return result
            }

            function convertToCSV(data) {
                const header = Object.keys(data[0]).join(',');
                const rows = data.map(row => Object.values(row).join(',')).join('\n');
                return `${header}\n${rows}`;
            }

            function downloadCSV(data) {
                const csvData = convertToCSV(data);
                const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement("a");
                
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", "data.csv");
                link.style.visibility = "hidden";
                
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);  // optional: free up memory
            }

            function DataDownloader() {
                const handleDownload = () => {
                    const data = DataPacket();
                    downloadCSV(data);
                    console.log("Data downloaded!");
                };

                return (
                    <button id="downloadButton" onClick={handleDownload}>
                        Download CSV
                    </button>
                );
            }

            var downloadCsvButton = DataDownloader()
            
            function Append(dataPacket,threshold) {
                
                var result = dataPacket;

                // Ensure result is an array
                if (!Array.isArray(result)) {
                    console.error("Result is not an array:", result);
                    return;
                }

                // Ensure threshold is a number to prevent unexpected type coercion issues in comparisons
                console.log('Result array:', result);
                console.log('Threshold:', threshold);

                let foundRecord = null;

                for (let i=0; i < result.length; i++) {
                    if (
                        (unit === 'F' && calcType === 'Simple' && result[i].gddFahrenheitSimpleCumsum > threshold) ||
                        (unit === 'F' && calcType === 'BE' && result[i].gddFahrenheitSineCumSum > threshold) ||
                        (unit === 'C' && calcType === 'Simple' && result[i].gddCelsiusSimpleCumSum > threshold) ||
                        (unit === 'C' && calcType === 'BE' && result[i].gddCelsiusSineCumSum > threshold)
                    ) {
                        foundRecord = result[i];
                        console.log(`Record found at index ${i}:`, foundRecord);
                        break;
                    }
                }

                if (!foundRecord) {
                    console.error("No object found matching these conditions.");
                    document.getElementById('dateDisplay').innerText = "No date found"; // Update here
                    return;
                } else {
                    document.getElementById('thresholdDateDisplay').innerText = foundRecord.date; // Update here
                    return foundRecord;
                }
            }

            var foundRecordExport = Append(DataPacket(),threshold)

            var thresholdDate = foundRecordExport.date

            console.log('Threshold date: ', thresholdDate)
            return downloadCsvButton

        }
        var date = FetchData()
        return date
    }

    handleDownloadCSV() {
        console.log('handleDownloadCSV called');
        const { csvData } = this.state;
        console.log('CSV Data:', csvData);
        if (csvData) {
          downloadCSV(csvData);
        }
      }

    render () {
      return (
        <div className="grid grid-cols-2 gap-4">
            <div
                name="input"
                className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto mt-8"
            >
                <h2 className="text-3xl font-extrabold mb-4 text-blue-800">Model Parameters</h2>
                    <h2>Location</h2>
                    <input
                    type="text"
                    name="longitude"
                    value={this.state.parameters.location}
                    onChange={this.handleLocationChange.bind(this)}
                    style={{ width: "300px" }}
                    />
                    <h2>Latitude (decimal degrees)</h2>
                    <input
                    type="text"
                    name="latitude"
                    value={this.state.parameters.latitude}
                    onChange={this.handleLatitudeChange.bind(this)}
                    style={{ width: "150px" }}
                    />
                    <h2>Longitude (decimal degrees, must be negative)</h2>
                    <input
                    type="text"
                    name="longitude"
                    value={this.state.parameters.longitude}
                    onChange={this.handleLongitudeChange.bind(this)}
                    style={{ width: "150px" }}
                    />
                    <h2>Base temperature</h2>
                    <input 
                        type="number"
                        step="0.1"
                        min="0"
                        max="100" 
                        name="baseTemperature"
                        value={this.state.parameters.baseTemperature}
                        onChange={this.handleBaseTemperatureChange.bind(this)}
                        style={{ width: "150px "}} />
                    <h2>Temperature units</h2>
                    <select
                        name="temperatureUnit"
                        value={this.state.parameters.temperatureUnit}
                        onChange={this.handleTemperatureUnitChange.bind(this)}
                        style={{ width: "200px "}} >
                            <option value="">Choose...</option>
                            <option value="F">Fahrenheit</option>
                            <option value="C">Celsius</option>
                    </select>
                    <h2>Calculation type</h2>
                    <select
                        name="calculationType"
                        value={this.state.parameters.calculationType}
                        onChange={this.handleCalculationTypeChange.bind(this)}
                        style={{ width: "275px" }} >
                            <option value="">Choose...</option>
                            <option value="Simple">Simple daily average</option>
                            <option value="BE">Baskerville Emin (sine wave)</option>
                    </select>
                    <h2>Start date</h2>
                    <input 
                        type="date" 
                        name="startDate"
                        value={this.state.parameters.startDate}
                        onChange={this.handleStartDateChange.bind(this)} />
                    <h2>End date</h2>
                    <input 
                        type="date" 
                        name="startDate"
                        value={this.state.parameters.endDate}
                        onChange={this.handleEndDateChange.bind(this)} />
                    <h2>GDD Threshold</h2>
                    <input 
                        type="number" 
                        name="gddThreshold"
                        value={this.state.parameters.gddThreshold}
                        onChange={this.handleGddThresholdChange.bind(this)}
                        style={{ width: "100px "}} />
                    <br /><br />
                    <button
                        onClick={this.handleButtonClicked.bind(this)}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg hover:shadow-xl transition duration-200"
                    >
                        Calculate Threshold Date
                    </button>
            </div>
            <div 
                name="output" 
                className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto mt-8"
            >
                <h2 className="text-3xl font-extrabold mb-4 text-blue-800">
                    {this.state.parameters.location}
                </h2>
                
                <p className="text-lg text-gray-800 mb-2 font-medium">
                    Base <span className="text-black">{this.state.parameters.baseTemperature}&deg; 
                    {this.state.parameters.temperatureUnit}</span>&nbsp;
                    {this.state.parameters.calculationType} GDD Model
                </p>
                <p className="text-lg text-gray-800 mb-2 font-medium">
                    <span className="text-black">{this.state.parameters.startDate}</span> to <span className="text-black">{this.state.parameters.endDate}</span>
                </p>
                <p className="text-lg text-gray-800 mb-4 font-medium">
                    <span className="text-black">{this.state.parameters.gddThreshold}</span> GDD threshold
                </p>
                
                <div id='datePlaceholder' className="mb-2"></div>
                <div className="text-lg">
                    Threshold date: <mark><span id='thresholdDateDisplay' className="font-bold mb-4"></span></mark>
                </div>
                <br/><br/>
                <a
                href={`data:text/csv;charset=utf-8,${encodeURIComponent(this.state.csvData)}`}
                download='data.csv'
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg hover:shadow-xl transition duration-200"
                style={{ textDecoration: 'none' }}
                >
                Download Dataset CSV
                </a>
            <br /><br />
        </div>

        </div>
      );
    }
}

export default function SeedcornMaggotSandbox() {

    return (
        <div className="bd-example example-padding" style={{padding: "0rem"}}>
            <div className="p-5">
                <h1><b>Sandbox | Seedcorn maggot GDD modeling</b></h1>
            <ModelInputs></ModelInputs>
            </div>
        </div>
    )
}


